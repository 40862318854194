<template>
    <div class="calendar-wrapper">
        <calendar
            ref="calendar"
            class="custom-calendar max-w-full"
            disable-page-swipe
            is-expanded
            trim-weeks
            title-position="left"
            :masks="masks"
            @update:to-page="updateMonth"
        >
            <template #header-right-button>
                <everest-button
                    class="today-button"
                    type="light"
                    @click.prevent.stop="moveToToday"
                >
                    {{ $t("today") }}
                </everest-button>
                <icon
                    class="nav-arrow"
                    name="chevron-right"
                />
            </template>
            <template #day-content="{ day }">
                <social-calendar-day
                    :day="day"
                    :happenings="happeningsForDay(day.day)"
                />
            </template>
        </calendar>
    </div>
</template>

<script>
import SocialCalendarDay from "@/views/events/SocialCalendarDay";
import { socialOverviewService } from "@/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import EverestButton from "@/components/Button";

export default {
    name: "SocialHappeningsTab",
    components: {
        EverestButton,
        SocialCalendarDay,
    },
    data() {
        return {
            masks: {
                weekdays: "WWW",
            },
            socialHappenings: [],
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    async mounted() {
        this.$emit("tab-changed", "social");
    },
    methods: {
        // update month is also triggered by calendar init
        async updateMonth({ month, year }) {
            // In JS Date the month starts at 0, but v-calendar starts at 1
            this.socialHappenings = await socialOverviewService.list(this.intranetUid, month - 1, year)
                .catch((e) => {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("social_overview_loading_error_title"),
                        text: e.message,
                    });
                });
        },
        async moveToToday() {
            const calendar = this.$refs.calendar;
            if (!calendar) return;
            await calendar.move(new Date());
        },
        happeningsForDay(day) {
            if (!this.socialHappenings) return [];
            return this.socialHappenings.filter((happening) => {
                return happening.date.$D === day;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.calendar-wrapper {
    overflow: auto;
}

::v-deep .custom-calendar.vc-container {
    --day-border: 1px solid var(--low-contrast);
    --day-border-highlight: 1px solid var(--low-contrast);
    --day-width: calc(4 * 32px + 1.55rem);
    --day-height: 15rem;
    --weekday-border: 1px solid var(--low-contrast);
    border-radius: 0;
    border: none;
    width: 100%;
    .vc-header {
        padding: 10px 0;
        .vc-title {
            // copied h2 styling
            font-size: 2rem;
            line-height: 1.2;
            letter-spacing: 0.01em;
            font-weight: 600;
            margin-bottom: 1.5rem;
            -webkit-user-drag: none;
            color: var(--highest-contrast);
        }
    }
    .vc-arrows-container {
        padding-right: 0;
        .vc-arrow {
            color: var(--highest-contrast);
            background-color: var(--background);
        }
        .vc-arrow.is-left:hover {
            color: var(--primary);
        }
        .vc-arrow.is-right {
            &:hover {
                background-color: var(--background);
            }
            .nav-arrow {
                margin-left: 3px;
                border-radius: var(--rounded);
                width: 30px;
                height: 40px;
                &:hover {
                    color: var(--primary);
                }
            }
            .today-button {
                &:hover {
                    background-color: var(--low-contrast);
                }
            }
        }
    }
    .vc-weeks {
        padding: 0;
    }
    .vc-weekday {
        border-bottom: var(--weekday-border);
        padding: 5px 0;
        color: var(--highest-contrast);
        text-align: left;
        font-weight: normal;
    }
    .vc-day {
        overflow: auto;
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        background-color: white;
        border-bottom: var(--day-border);
        border-right: var(--day-border);
        &.on-left {
            border-left: var(--day-border);
        }
        &.is-today {
            background-color: var(--lowest-contrast);
        }

        .vc-day-content {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
        }
    }
    .vc-day-dots {
        margin-bottom: 5px;
    }
}
</style>
