<template>
    <div class="calendar-day pbottom-medium">
        <div class="header bold">
            {{ day.day }}
        </div>
        <social-happening-group
            v-if="birthdays.length > 0"
            class="group"
            type="primary"
            :social-notification-type="SocialNotificationType.birthday"
            :happenings="birthdays"
            :day="day"
        >
            <template #title>
                <span class="social-happening-group-title">{{ $tc("birthday", 1) }} 🎉</span>
            </template>
        </social-happening-group>
        <social-happening-group
            v-if="anniversaries.length > 0"
            class="group"
            type="secondary"
            :social-notification-type="SocialNotificationType.anniversary"
            :happenings="anniversaries"
            :day="day"
        >
            <template #title>
                <span class="social-happening-group-title">{{ $tc("anniversary", 1) }} 🙌</span>
            </template>
        </social-happening-group>
        <social-happening-group
            v-if="newEmployees.length > 0"
            class="group"
            type="primary"
            social-notification-type="NEW_EMPLOYEE"
            :happenings="newEmployees"
        >
            <template #title>
                <span class="social-happening-group-title">{{ $t("welcome_aboard") }} 👋</span>
            </template>
        </social-happening-group>
    </div>
</template>

<script>
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import SocialHappeningGroup from "@/views/events/SocialHappeningGroup";

export default {
    name: "SocialCalendarDay",
    components: { SocialHappeningGroup },
    props: {
        /**
         * @see SocialHappening []
         */
        happenings: { type: Array, required: true },
        day: { type: Object, required: true },
    },
    data() {
        return {
            SocialNotificationType: SocialNotificationType,
        };
    },
    computed: {
        birthdays() {
            return this.happenings.filter((happening) => happening.type === SocialNotificationType.birthday);
        },
        anniversaries() {
            return this.happenings.filter((happening) => happening.type === SocialNotificationType.anniversary);
        },
        newEmployees() {
            return this.happenings.filter((happening) => happening.type === SocialNotificationType.newEmployee);
        },
    }
};
</script>

<style lang="scss" scoped>
.calendar-day {
    .header, .group {
        margin: $spacing-xxsmall 0;
    }
    .header {
        text-align: right;
    }
    .social-happening-group-title {
        position: relative;
    }
}
</style>
