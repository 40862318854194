<template>
    <div
        class="happening-group"
        :class="type"
    >
        <div class="title bold padding-xxsmall">
            <div class="bubble bubble-bottom-left"></div>
            <div class="bubble bubble-top-right"></div>
            <div class="bubble bubble-center-right"></div>
            <slot name="title">
                {{ socialNotificationType }}
            </slot>
        </div>
        <avatar-list
            class="avatar-list padding-xxsmall"
            :zoom-on-hover="false"
            :loaded-user-profiles="userProfiles.slice(0, 7)"
            :user-total="userProfiles.length"
            :stacked="false"
            :show-tool-tips="true"
            :tooltip-generator="tooltipGenerator"
            :v-card-on-hover="false"
            @click-on-hidden-users="showDetailModal"
            @avatar-clicked="logUserProfileNavigation"
        />
    </div>
</template>

<script>
import AvatarList from "@/components/AvatarList";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import {
    dayjsFromDate,
    dayjsFromDateYMD,
} from "@/lib/time-utils";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { escape } from "lodash";
import dayjs from "dayjs";

export default {
    name: "SocialHappeningGroup",
    components: { AvatarList },
    props: {
        socialNotificationType: { type: String, default: SocialNotificationType.birthday },
        /**
         * accepted values: "primary", "secondary"
         */
        type: {
            type: String,
            required: true,
            validator: (type) => ["primary", "secondary"].includes(type),
        },
        happenings: { type: Array, required: true },
        day: undefined,
    },
    computed: {
        userProfiles() {
            return this.happenings.map((happening) => happening.user);
        },
        tooltipGenerator() {
            if (this.socialNotificationType === SocialNotificationType.birthday) {
                return this.birthdayTooltip;
            }
            if (this.socialNotificationType === SocialNotificationType.anniversary) {
                return this.anniversaryTooltip;
            }
            return undefined;
        },
        modalTitle() {
            if (this.socialNotificationType === SocialNotificationType.birthday) {
                return this.$tc("birthday", 2);
            }
            if (this.socialNotificationType === SocialNotificationType.anniversary) {
                return this.$tc("anniversary", 2);
            }
        },
    },
    methods: {
        logUserProfileNavigation() {
            analytics.log(getGlobalConfiguration().analytics_event_name_social_overview_navigation_to_user_profile);
        },
        birthdayTooltip(user) {
            const fullUserProfile = this.happenings
                .map((happening) => happening.user)
                .find((fullUser) => fullUser.uid === user.uid);
            return this.tooltipGeneratorFunction(user, fullUserProfile.birthday, dayjsFromDate(new Date(this.day.date)));
        },
        anniversaryTooltip(user) {
            const fullUserProfile = this.happenings
                .map((happening) => happening.user)
                .find((fullUser) => fullUser.uid === user.uid);
            return this.tooltipGeneratorFunction(user, fullUserProfile.hiringDate, dayjsFromDate(new Date(this.day.date)));
        },
        tooltipGeneratorFunction(user, dateYMD, compareDate) {
            const anchorDate = compareDate || dayjs();
            const timeDifference = anchorDate.locale("en").diff(dayjsFromDateYMD(dateYMD), "years");
            // https://lodash.com/docs/4.17.15#escape
            const escapedDisplayName = escape(user.displayName);
            return `${escapedDisplayName}<br/>(${timeDifference} years)`;
        },
        showDetailModal() {
            this.$modal.show("modal", {
                component: AvatarList,
                props: {
                    loadedUserProfiles: this.userProfiles,
                    mode: "list",
                },
                modalProps: {
                    title: this.modalTitle,
                    unavoidable: false,
                },
                events: {
                    clickOnUser: () => {
                        this.hideDetailModal();
                        this.logUserProfileNavigation();
                    },
                },
            });
        },
        hideDetailModal() {
            this.$modal.hide("modal");
        },
    },
};
</script>

<style lang="scss" scoped>
.bubble {
    position: absolute;
    background: var(--background);
    opacity: .3;
    border-radius: 50%;
    &.bubble-bottom-left {
        width: 60%;
        padding-bottom: 60%;
        right: 60%;
        top: 75%
    }
    &.bubble-top-right {
        width: 60%;
        padding-bottom: 60%;
        left: 55%;
        bottom: 20%;
    }
    &.bubble-center-right {
        width: 60%;
        padding-bottom: 60%;
        left: 80%;
        bottom: -150%;
    }
}

.happening-group {
    background: var(--background);
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: var(--border-radius-small);
    &.primary {
        border: 2px solid var(--primary);
        .title {
            width: 100%;
            background-color: var(--primary);
        }
    }
    &.secondary {
        border: 2px solid var(--secondary);
        .title {
            width: 100%;
            background-color: var(--secondary);
        }
    }

    .title {
        color: white;
        position: relative;
        z-index: 1;
    }

    .avatar-list {
        position: relative;
        z-index: 1;
    }
}
</style>
